
import Vue from "vue";
import Component from "vue-class-component";
import UserActivityPanel from "@/components/UserActivityPanel.vue";
import CurrentSummaryPanel from "@/components/CurrentSummaryPanel.vue";
import PageTabs from "@/components/PageTabs.vue";
import ReviewLockModal, { IInputData } from "@/components/ReviewLockModal.vue";
import { AppModule } from "@/store/modules/app";
import { UserModule } from "@/store/modules/user";

import { Watch } from "vue-property-decorator";
import {
  IAccountSummary,
  IPendingWithdrawal,
  IValidator,
  IValidatorAndVotesItem,
} from '@maplabs/map-atlas-sdk';
import {
  normalValueToWeiBignumber,
  weiBigNumberToNormalValue,
  weiBigNumberToNormalValueFixed,
} from "@/utils/tools";
import { BigNumber } from "bignumber.js";
import Web3 from "web3";

@Component({
  components: {
    ReviewLockModal,
    PageTabs,
    CurrentSummaryPanel,
    UserActivityPanel,
  },
})
export default class LockPage extends Vue {
  public tabIndex = 0;

  public summaryType = 0;

  public reviewLock = false;

  private timeStamp: string | number = 0.0;

  private canWithdrawValue = new BigNumber(0);

  private canWithdrawIndexes: number[] = [];

  private votesForValidator: IValidator[] = [];

  private summaryList: { [key: string]: string | number }[] = [];

  private userDatalist: { [key: string]: string | number }[] = [];

  private summaryTotal = 0;

  private loading = true;

  public modalData: IInputData = {
    type: "",
    value: "0",
    fee: "0",
    extraData: { name: "" },
  };

  public accountSummary: IAccountSummary = {
    userBalance: {
      totalLocked: new BigNumber(0),
      nonVoting: new BigNumber(0),
      balance: new BigNumber(0),
      pendingWithdraw: new BigNumber(0),
      requirement: new BigNumber(0),
    },
    pendingWithdrawals: [],
  };

  public voteHistory: IValidatorAndVotesItem[] = [];

  public inputValue = "";

  private get continueEnable() {
    return this.tabIndex === 0 ? parseFloat(this.inputValue) >= 10 : parseFloat(this.inputValue, 10) > 0;
  }

  // bottom data preview
  private get balanceResult() {
    const result = {
      unlocked: this.accountSummary.userBalance.balance,
      pending: this.accountSummary.userBalance.pendingWithdraw,
      locked: this.accountSummary.userBalance.totalLocked,
    };
    const inputInfo = normalValueToWeiBignumber(this.inputValue);
    switch (this.tabIndex) {
      case 0:
        result.unlocked = this.accountSummary.userBalance.balance.minus(inputInfo);
        result.locked = this.accountSummary.userBalance.totalLocked.plus(inputInfo);
        break;
      case 1:
        result.pending = this.accountSummary.userBalance.pendingWithdraw.plus(inputInfo);
        result.locked = this.accountSummary.userBalance.totalLocked.minus(inputInfo);
        break;
      case 2:
        result.pending = this.accountSummary.userBalance.pendingWithdraw.minus(inputInfo);
        result.unlocked = this.accountSummary.userBalance.balance.plus(inputInfo);
        break;
      default:
        break;
    }
    return result;
  }

  private tapMax() {
    switch (this.tabIndex) {
      case 0:
        this.inputValue = weiBigNumberToNormalValue(this.accountSummary?.userBalance.balance);
        break;
      case 1:
        this.inputValue = weiBigNumberToNormalValue(this.accountSummary?.userBalance.nonVoting);
        break;
      default:
        break;
    }
  }

  public onContinue() {
    switch (this.tabIndex) {
      case 0:
        this.startLock();
        break;
      case 1:
        this.startUnlock();
        break;
      case 2:
        this.startWithdraw();
        break;
      default:
        break;
    }
  }

  private startLock() {
    const unlockedValue =
      parseFloat(weiBigNumberToNormalValue(this.accountSummary.userBalance.balance)) -
      parseFloat(this.inputValue);

    const pendingValue = weiBigNumberToNormalValue(this.accountSummary.userBalance.pendingWithdraw);

    const lockedValue =
      parseFloat(weiBigNumberToNormalValue(this.accountSummary.userBalance.totalLocked)) +
      parseFloat(this.inputValue);

    this.modalData = {
      type: this.$t("lock"),
      value: this.inputValue,
      fee: "0",
      extraData: {
        Unlocked: `${unlockedValue}`,
        Pending: pendingValue,
        Locked: `${lockedValue}`,
      },
    };
    this.reviewLock = true;
  }

  private startUnlock() {
    const unlockedValue = weiBigNumberToNormalValue(this.accountSummary.userBalance.balance);

    const pendingValue =
      parseFloat(weiBigNumberToNormalValue(this.accountSummary.userBalance.pendingWithdraw)) +
      parseFloat(this.inputValue);

    const lockedValue =
      parseFloat(weiBigNumberToNormalValue(this.accountSummary.userBalance.totalLocked)) -
      parseFloat(this.inputValue);

    this.modalData = {
      type: this.$t("Unlock"),
      value: this.inputValue,
      fee: "0",
      extraData: {
        Unlocked: `${unlockedValue}`,
        Pending: `${pendingValue}`,
        Locked: `${lockedValue}`,
      },
    };
    this.reviewLock = true;
  }

  private startWithdraw() {
    const unlockedValue =
      parseFloat(weiBigNumberToNormalValue(this.accountSummary.userBalance.balance)) +
      parseFloat(this.inputValue);

    const pendingValue =
      parseFloat(weiBigNumberToNormalValue(this.accountSummary.userBalance.pendingWithdraw)) -
      parseFloat(this.inputValue);

    const lockedValue = parseFloat(
      weiBigNumberToNormalValue(this.accountSummary.userBalance.totalLocked)
    );

    this.modalData = {
      type: this.$t("Withdraw"),
      value: this.inputValue,
      fee: "0",
      extraData: {
        Unlocked: `${unlockedValue}`,
        Pending: `${pendingValue}`,
        Locked: `${lockedValue}`,
      },
    };
    this.reviewLock = true;
  }

  private async modalConfirm() {
    this.reviewLock = false;
    AppModule.START_LOADING("Sending");
    if (UserModule.contractKit) {
      try {
        const value = new BigNumber(Web3.utils.toWei(this.inputValue).toString());
        switch (this.tabIndex) {
          case 0:
            await UserModule.contractKit.lockedContract.lock(value);
            break;
          case 1:
            await UserModule.contractKit.lockedContract.unlock(value);
            break;
          case 2:
            await this.runAllWithdraw();
            break;
          default:
            break;
        }
        AppModule.END_LOADING();
        AppModule.TOAST({
          type: "success",
          message: "Success",
        });
        this.reviewLock = false;
        await this.loadData();
      } catch (e) {
        console.error(e);
        AppModule.END_LOADING();
        AppModule.TOAST({
          type: "error",
          message: e.message,
        });
      }
    }
  }

  private async withdraw(index: number) {
    const res = await  UserModule.contractKit?.getAccountSummary(UserModule.address);
    res?.pendingWithdrawals.forEach((item, index) => {
      console.log(item.value.toString(), 'value', index);
    })
    return UserModule.contractKit?.lockedContract.withdraw(index);
  }

  private async runAllWithdraw() {
    let p = Promise.resolve();
    const arr: any[] = [];
    this.canWithdrawIndexes.forEach((item) => {
      p = p
        .then(() => this.withdraw(item))
        .then((res) => {
          arr.push(res);
          return arr;
        });
    });
    return p;
  }

  private inputChange() {
    let compareValue = new BigNumber(0);
    switch (this.tabIndex) {
      case 0:
        compareValue = this.accountSummary.userBalance.balance;
        break;
      case 1:
        compareValue = this.accountSummary.userBalance.nonVoting;
        break;
      default:
        break;
    }
    if ((new BigNumber(this.inputValue)).isGreaterThan(new BigNumber(weiBigNumberToNormalValue(compareValue)))) {
      this.inputValue = weiBigNumberToNormalValue(compareValue);
    }
  }

  private async loadData() {
    this.inputValue = "";
    this.loading = true;
    if (UserModule.contractKit) {
      [this.accountSummary, this.voteHistory, this.timeStamp, this.votesForValidator] =
        await Promise.all([
          UserModule.contractKit.getAccountSummary(UserModule.address),
          UserModule.contractKit.getValidatorsAndVotesVotedByAccount(UserModule.address),
          UserModule.contractKit.getTimeStamp(),
          UserModule.contractKit.getAccountAllVotedValidatorsAndInfo(UserModule.address),
        ]);

      this.canWithdrawIndexes = [];
      this.canWithdrawValue = new BigNumber(0);

      const pendingWithdraws = [...this.accountSummary.pendingWithdrawals];

      // calculate all can withdraw info
      this.getCanWithdrawIndexes(pendingWithdraws);


      const arr = this.voteHistory.map((item) => ({
        name: item.name,
        value: parseFloat(weiBigNumberToNormalValueFixed(item.votes)),
        address: item.address
      }));
      this.summaryTotal = parseFloat(
        weiBigNumberToNormalValueFixed(this.accountSummary.userBalance.totalLocked)
      );
      arr.push({
        name: "Unused Locked",
        value: parseFloat(
          weiBigNumberToNormalValueFixed(this.accountSummary.userBalance.nonVoting)
        ),
        address: "",
      });
      this.summaryList = arr;

      this.userDatalist = [
        {
          name: "Unlocked",
          value: weiBigNumberToNormalValueFixed(this.accountSummary.userBalance.balance),
        },
        {
          name: "Pending",
          value: weiBigNumberToNormalValueFixed(this.accountSummary.userBalance.pendingWithdraw),
        },
        {
          name: "Locked",
          value: weiBigNumberToNormalValueFixed(this.accountSummary.userBalance.totalLocked),
        },
        {
          name: "Total",
          value: weiBigNumberToNormalValueFixed(
            this.accountSummary.userBalance.balance.plus(
              this.accountSummary.userBalance.totalLocked
            )
          ),
        },
      ];
    }
    this.loading = false;
  }

  private getCanWithdrawIndexes(pendingWithdrawals: IPendingWithdrawal[]): void {
    let suitableIndex = -1;
    for (let i = 0, length = pendingWithdrawals.length; i < length; i += 1) {
      const item = pendingWithdrawals[i];
      if (parseFloat(`${this.timeStamp}`) >= parseFloat(item.time)) {
        this.canWithdrawValue = this.canWithdrawValue.plus(item.value);
        this.canWithdrawIndexes.push(i);
        suitableIndex = i;
        break;
      }
    }
    if (suitableIndex === -1) {
      return;
    }
    // WARN: According to the algorithm of `LockContract` on chain, if contract changes ,this should to be change
    const lastIndex = pendingWithdrawals.length - 1;
    pendingWithdrawals[suitableIndex] = pendingWithdrawals[lastIndex];
    pendingWithdrawals.pop();
    return this.getCanWithdrawIndexes(pendingWithdrawals);
  }

  private created() {
    this.loadData();
  }

  @Watch("tabIndex")
  private tabChange(value: number) {
    if (value === 2) {
      this.inputValue = weiBigNumberToNormalValue(this.canWithdrawValue);
    } else {
      this.inputValue = "";
    }
  }

  @Watch("UserModule.connected")
  private connectChange(value: boolean) {
    if (value) {
      this.loadData();
    }
  }
}
